import React, {useEffect} from 'react';
import {embedDashboard} from '@superset-ui/embedded-sdk';
import {useLocation, useParams} from 'react-router-dom';

const SupersetDashboard = () => {

  const { dashboardID } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const user = queryParams.get('user');
  const firstName = queryParams.get('firstName');
  const lastName = queryParams.get('lastName');
  useEffect(() => {
    async function fetchToken() {
      const token = await getTokenLocal();
      if (token) {
        embedDashboard({
          id: dashboardID,
          supersetDomain: 'https://superset.datenraum-dev.freiburg.de',
          mountPoint: document.getElementById("dashboard"),
          fetchGuestToken: () => token,
        });
        document.getElementById("dashboard").children[0].width="100%";
        document.getElementById("dashboard").children[0].height="100%";
      }
    }
    fetchToken();
  }, [dashboardID]);

    async function getTokenLocal() {
    try {
      const response = await fetch(`https://superset-embedded-backend.datenraum-dev.freiburg.de:443/get_guest_token?user=${user}&dashboard=${dashboardID}`);
      const data = await response.json();
      return data.message;
    } catch (error) {
      return null;
    }
  }
  return (
    <div id='dashboard' style={{width: '100%', height: '100vh'}}/>
  );
};
export default SupersetDashboard;
